import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Group } from '../../../../../../models/group';
import { GroupService } from '../../../../../../services/group.service';
import { TimeService } from 'src/app/services/time.service';

@Component({
  selector: 'groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  addingGroup:boolean;
  groups:Group[];
  getGroupsSubscription:Subscription;
  loading:boolean;
  
  constructor(private groupService:GroupService, private timeService:TimeService, private router:Router) { }

  ngOnInit() {
    this.loading = true;
    this.getGroupsSubscription = this.groupService.getGroups().subscribe(groups => {
      this.groups = groups.reverse();
      this.loading = false;
    });
  }

  ngOnDestroy () {
      this.getGroupsSubscription.unsubscribe();
  }

  addGroup():void {
    this.addingGroup = true;
    this.groupService.addGroup().subscribe((group) => {
        this.addingGroup = false;
        this.timeService.restart();
        this.router.navigate(['/admin/group/edit', group.id]);
    })
  }

  deleteGroup (group) {
    if (confirm('Biztos hogy törölni akarod a csoportot?')) {
      this.groupService.deleteGroup(group.id).subscribe(() => {
        const index = this.groups.indexOf(group);
        this.groups.splice(index, 1);
      })
    }
  }

  orderAsc (type:string) {
		this.groups.sort(function(a,b){
			if(a[type] < b[type]){
				return -1;
			}else if(a[type] > b[type]){
				return 1;
			}
			return 0;
		})
	}

	orderDesc (type:string) {
		this.groups.sort(function(a,b){
			if(a[type] > b[type]){
				return -1;
			}else if(a[type] < b[type]){
				return 1;
			}
			return 0;
		})
	}

}
