import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Note } from 'src/app/models/note';
import { User } from 'src/app/models/user';
import { NoteService } from 'src/app/services/note.service';
import { TimeService } from 'src/app/services/time.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {

  addingNote:boolean;
  error:boolean;
  loading:boolean;
  message:string
  notes:Note[];
  toggleSuccess:boolean;
	toggleSended;
	toggling:boolean;
  success:boolean;
  user:User;
  private notesSubsciption:Subscription;
  private addNoteSubsciption:Subscription;
  private deleteNoteSubsciption:Subscription;
  private lockedSubscription:Subscription;
  private userSubscription:Subscription;
  
  constructor(private noteService:NoteService, private timeService:TimeService, private userService:UserService, private router:Router) { }

  ngOnInit() {
    this.loading = true;
    this.notesSubsciption = this.noteService.getNotes(0).subscribe(notes => {
      this.loading = false;
      this.notes = notes
    });
    this.userSubscription = this.userService.getUserSubject().subscribe(user => {
			this.user = user;
		})
  }

  ngOnDestroy() {
    this.notesSubsciption.unsubscribe();
    this.userSubscription.unsubscribe();
    if (this.addNoteSubsciption) {
      this.addNoteSubsciption.unsubscribe();
    }
    if (this.deleteNoteSubsciption) {
      this.deleteNoteSubsciption.unsubscribe();
    }
    if (this.lockedSubscription) {
      this.lockedSubscription.unsubscribe();
    }

  }

  addNote () {
    this.addingNote = true;
    this.addNoteSubsciption = this.noteService.addNote().subscribe((note:Note) => {
      this.router.navigate(['/admin/note/edit/', note.id]);
      this.timeService.restart();
      this.addingNote = false;
    })
  }

  deleteNote (note) {
    if (confirm('Biztosan törölni akarod ezt a jegyzetet?')){
        const index = this.notes.indexOf(note);  
        this.notes.splice(index, 1);
          this.deleteNoteSubsciption = this.noteService.deleteNote(note.id).subscribe(
            () => {
              this.success = true;
            }, 
            () => {
              this.error = true;
              this.notes.splice(index,  0, note)
            }
          )
          setTimeout(() => {
            this.success = false;
            this.error = false;
          },10000)
        }
    }

    toggleLockNote (note:Note, locked:boolean) {
      if (confirm('Biztosan fel akarod oldani / le akarod zárni ezt a jegyzetet?')) {
        this.toggling = true;
        this.lockedSubscription = this.noteService.saveLocked(note.id, locked).subscribe((response:any) => {
          if (response.success) {
            note.locked = locked;
          }
          this.toggleSuccess = response.success;
          this.toggleSended = true; 
          this.toggling = false;
        });
        setTimeout(() => {
          this.toggleSended = false;
        }, 20000)
      }
    }
}
