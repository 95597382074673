import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  private acknowledged:boolean;
  private key = 'acknowledged'
  
  constructor() { }

  getAcknowledged () {
    if (!this.acknowledged) {
      this.acknowledged = (localStorage.getItem(this.key) == 'true');
    }
    return this.acknowledged;
  }

  setAcknowledged (acknowledged:boolean) {
    this.acknowledged = acknowledged;
    localStorage.setItem(this.key, JSON.stringify(this.acknowledged))
  }
}
