import { Directive, HostListener, Input, ElementRef, OnInit } from '@angular/core';
import { TimeService } from 'src/app/services/time.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { JwtService } from 'src/app/services/jwt.service';

@Directive({
  selector: '[navigate]'
})
export class NavigateDirective implements OnInit {

  @Input('navigate') navParams:any[];

  constructor(private jwtService:JwtService, private timeService:TimeService, private userService:UserService, private elementRef:ElementRef, private router:Router) { }

  ngOnInit () {
     this.elementRef.nativeElement.href = this.navParams.join('/');
  }
  
  @HostListener('click', ['$event'])
  onClick (event) {
    event.preventDefault();
    this.timeService.restart();
    this.userService.refreshToken().subscribe((response:any) => {
        this.jwtService.setToken(response.token);
    })
    this.router.navigate(this.navParams);
  }

}
