import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { components } from './declarations';
import { TestModule } from '../test/test.module';
import { FillService } from '../../../../services/fill.service';
import { NetworkService } from '../../../../services/network.service';
import { JwtService } from '../../../../services/jwt.service';
import { SharedModule } from 'src/app/modules/shared/shared.module';

@NgModule({
  imports: [
    CommonModule, FormsModule, HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NoopAnimationsModule,
    RouterModule,
    TestModule,
    SharedModule
  ],
  providers:[FillService, NetworkService, JwtService,  {
    provide: MAT_DATE_LOCALE, useValue: 'hu-HU'
  }],
  declarations: [components]
})
export class FillModule { }
