import { Component, EventEmitter, OnInit, ViewEncapsulation, Output, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { Group } from 'src/app/models/group';
import { User } from 'src/app/models/user';
import { FillFilter } from 'src/app/models/fill-filter';
import { GroupService } from 'src/app/services/group.service';
import { UserService } from 'src/app/services/user.service';
import { GroupType } from 'src/app/models/group-type';
import { Test } from 'src/app/models/test';
import { GroupTypeService } from 'src/app/services/group-type.service';
import { TestService } from 'src/app/services/test.service';

@Component({
  selector: 'fill-filter',
  templateUrl: './fill-filter.component.html',
  styleUrls: ['./fill-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FillFilterComponent implements OnInit, OnDestroy {

  displayedTests:Test[];
  displayedUsers:User[];
  filter:FillFilter;
  groups:Group[];
  groupTypes:GroupType[];
  users:User[];
  tests:Test[];
  @Output('filterchange') filterChange:EventEmitter<FillFilter> = new EventEmitter();
  private groupsSubscription:Subscription;
  private groupTypesSubscription:Subscription;
  private testsSubscription:Subscription;
  private usersSubscription:Subscription;
  
  constructor(private groupService:GroupService, private groupTypeService:GroupTypeService, private testService:TestService, private userService:UserService) { }

  ngOnInit() {
    this.filter = {};
    this.groupsSubscription = this.groupService.getGroups().subscribe(groups => {
      this.groups = groups;
    })
    this.usersSubscription = this.userService.getUsers(0).subscribe(users => {
        this.users = this.displayedUsers = users;
    })
    this.groupTypesSubscription = this.groupTypeService.getGroupTypes().subscribe(groupTypes => {
      this.groupTypes = groupTypes;
    })
    this.testsSubscription = this.testService.getTests(false, 0).subscribe(tests => {
      this.tests = this.displayedTests = tests;
    })

  }
  
  ngOnDestroy () {
    this.groupsSubscription.unsubscribe();
  }

  filterTests () {
    this.displayedTests = this.testService.filterTestsByGroupTypeId(this.tests, this.filter.groupType);
  }

  filterUsers () {
    this.displayedUsers = this.userService.filterUsersByGroupId(this.users, this.filter.group);
  }

  submit (form) {
    this.filterChange.emit(this.filter);
  }

}
