import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';

import { saveAs } from 'file-saver';

import { Observable, Subscription } from 'rxjs';

import { FillService } from '../../../../../../services/fill.service';
import { Fill } from '../../../../../../models/fill';
import { FillFilter } from 'src/app/models/fill-filter';


@Component({
	templateUrl:'./fills.component.html',
})

export class FillsComponent implements OnInit, OnDestroy{
	
	displayedFills:Fill[];
	exporting:boolean;
	loading:boolean;
	messages:string[] = [];
	testId:number;
	fills:Fill[];
	filtering:boolean;
	private deleteFillSubscription:Subscription;
	private exportCsvSubscription:Subscription;
	private getFillsSubscription:Subscription;

	constructor(private fillService:FillService, private router:Router, private route:ActivatedRoute){}

	ngOnInit () {
		this.loading = true;
		if (this.route.snapshot.paramMap.get('testId')) {
			this.testId = +this.route.snapshot.paramMap.get('testId');
		}
		let getFills =  (this.testId) ? this.fillService.getFills(this.testId) : this.fillService.getFills();
		this.getFillsSubscription = getFills.subscribe((fills:Fill[]) => {
			this.fills = this.displayedFills = fills.reverse();
			this.loading = false;
		});
	}

	ngOnDestroy () {
		this.getFillsSubscription.unsubscribe();
		if (this.deleteFillSubscription) {
			this.deleteFillSubscription.unsubscribe();
		}
		if (this.exportCsvSubscription) {
			this.exportCsvSubscription.unsubscribe();
		}
	}

	checkAll () {
		this.fills = this.fills.map(fill => {
		  fill.checked = true;
		  return fill;
		})
	}
		
	exportCsv () {
			this.exporting = true;
			const ids:number[] = [];

			this.displayedFills.forEach(fill => {
				ids.push(fill.id);
			})

			this.exportCsvSubscription = this.fillService.exportCsv(ids).subscribe((text:any) => {
				const BOM = "\uFEFF";
				const blob = new Blob([BOM + text], {type: 'application/vnd.ms-excel;Charset=UTF-8'});
				saveAs(blob, 'kitoltok.csv');
				this.exporting = false;
			})
	}
	
	  deleteChecked () {
			if (confirm('Biztosan törölni akarod a kitöltéseket?')){
				const deletableFills:Fill[] = [];
				this.displayedFills = this.displayedFills.filter((fill, index) => {
						if(fill.checked) {
							fill.index = index;
							deletableFills.push(fill);
							return false;
						} else {
							return true;
						}
				});

				for (let deleteableFill of deletableFills) {
					setTimeout(() => {
						const index = this.fills.indexOf(deleteableFill);
						this.fills.splice(index, 1);
						this.fillService.deleteFill(deleteableFill.id).subscribe(
							() => {}, 
							() => {
									this.messages.push(`A ${deleteableFill.id} azonosítójú kitöltés törlése sikertelen`);
									this.displayedFills.splice(deleteableFill.index,  0, deleteableFill);
									this.fills.splice(index,  0, deleteableFill);
								}
							)
					}, 500 )
					
				}
	
			
				setTimeout(() => {
					this.messages = [];
				},10000)
		}
	}

	onFilterChange(filter:FillFilter) {
		this.displayedFills = this.fillService.filterFills(this.fills, filter);
	}

	orderAsc (type:string) {
		this.fills.sort(function(a,b){
			if(a[type] < b[type]){
				return -1;
			}else if(a[type] > b[type]){
				return 1;
			}
			return 0;
		})
	}

	orderDesc (type:string) {
		this.fills.sort(function(a,b){
			if(a[type] > b[type]){
				return -1;
			}else if(a[type] < b[type]){
				return 1;
			}
			return 0;
		})
	}
	
	toggleFiltering () {
		this.filtering = !this.filtering;
	}
}