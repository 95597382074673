import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TestModule } from './modules/test/test.module';
import { HomeRoutingModule } from './modules/home-routing/home-routing.module';
import { RequestModule } from './modules/request/request.module';

import { HomeComponent } from './components/home/home.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { UserModule } from '../user/user.module';
import { NoteService } from 'src/app/services/note.service';
import { LogoComponent } from './components/logo/logo.component';
import { NoteModule } from './modules/note/note.module';
import { CookieBarComponent } from './components/cookie-bar/cookie-bar.component';
import { SharedModule } from '../shared/shared.module';
import { CopyrightComponent } from './components/copyright/copyright.component';

@NgModule({
  imports: [
    CommonModule,
    NoteModule,
    TestModule,
    UserModule,
    HomeRoutingModule,
    RouterModule,
    RequestModule,
    SharedModule
  ],
  declarations: [
      HomeComponent,
      HomePageComponent,
      LogoComponent,
      CookieBarComponent,
      CopyrightComponent
  ],
  providers: [
    NoteService
  ],
  exports:[
    HomeComponent
  ]
})
export class HomeModule { }
