import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'send-reset-password',
  templateUrl: './send-reset-password.component.html',
  styleUrls: ['./send-reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SendResetPasswordComponent implements OnInit, OnDestroy {

  isSended:boolean = false;
  email:string = '';
  found:boolean;
  sending:boolean;
  private sendResetPasswordSubscribtion:Subscription;
  
  constructor(private userService:UserService, private route:ActivatedRoute) { }

  ngOnInit() {
    
  }

  ngOnDestroy () {
    if (this.sendResetPasswordSubscribtion) {
      this.sendResetPasswordSubscribtion.unsubscribe();
    }
  }

  submit (form:NgForm) {
      this.sending = true;
      this.email = form.value.email;
      this.userService.sendResetPassword(this.email).subscribe((response:any) => {
        this.found = response.found;
        this.isSended = true;
        this.sending = false;
      });
      form.reset();
      setTimeout(() => {
        this.isSended = false;
      }, 20000)
  }

}
