import { Component, EventEmitter, Input, Output, DoCheck, OnInit, ViewEncapsulation } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { TimeService } from 'src/app/services/time.service';

@Component({
  selector: 'timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimerComponent implements DoCheck, OnInit {

  
  
  private countDownSeconds:number;
  private restartSubscription:Subscription;
  private timer;
  @Input('time') time:string;
  @Input('visible')  visible:boolean;
  @Input('restart') restart:Observable<string>;
  @Output('timeexpired') timeexpired:EventEmitter<boolean> = new EventEmitter();
  @Output('timealmostexpired') timealmostexpired:EventEmitter<boolean> = new EventEmitter();
  
  constructor(private timeService:TimeService) { }

  ngOnInit () {
    if (this.restart) {
      this.restartSubscription = this.restart.subscribe(time => {
        this.time = time;
        this.countDownSeconds = this.timeService.stringToSeconds(this.time);
      })
    }
  }

  ngDoCheck() {
    this.countDownSeconds = this.timeService.stringToSeconds(this.time);
  }

  ngOnDestroy () {
    if (this.restart) {
      this.restartSubscription.unsubscribe();
    }
  }

  startCountDown () {
    this.timer = setInterval(() => {
      this.countDownSeconds--;
      if (this.countDownSeconds === 5) {
        this.timealmostexpired.emit(true);
      }
			if(this.countDownSeconds === 0){
        this.timeexpired.emit(true);
        this.stopCountDown()
			}
			this.time = this.timeService.secondsToString(this.countDownSeconds);
		}, 1000)
  }

  stopCountDown () {
    clearInterval(this.timer);
  }

}
