import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PdfViewerModule } from 'ng2-pdf-viewer'

import { NoteComponent } from './components/note/note.component';
import { NotesComponent } from './components/notes/notes.component';
import { TestModule } from '../test/test.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PdfViewerModule,
    SharedModule,
    TestModule
  ],
  declarations: [ NoteComponent, NotesComponent],
  exports: [ NoteComponent, NotesComponent ]
})
export class NoteModule { }
