import { Component, Input, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';


import { Subscription } from 'rxjs';


import { Answer } from '../../../../../../models/answer';
import { Edited } from  '../../../../../../models/edited'
import { Question } from '../../../../../../models/question';

import { AnswerService } from "../../../../../../services/answer.service";
import { QuestionService } from '../../../../../../services/question.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
	selector:'question',
	templateUrl:'./question.component.html',
	styleUrls:['./question.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class QuestionComponent implements OnDestroy {


	addAnswerSubscription:Subscription;
	addingAnswer:boolean;
	correctOptions = [
		{ value: false, title:'Nem' },
		{ value: true, title: 'Igen' },
	]
	deleteAnswerSubscription:Subscription;
	saveTextSubscription:Subscription;
	saveTypeSubscription:Subscription;
	saveAnswerTextSubscription:Subscription;
	saveAnswerCorrectSubscription:Subscription;
	@Input('locked') locked:boolean;
	@Input('question') question:Question;

	constructor(private answerService:AnswerService, private questionService:QuestionService, private imageService:ImageService){};

	ngOnDestroy () {
		if (this.addAnswerSubscription){
			this.addAnswerSubscription.unsubscribe();
		}
		if (this.deleteAnswerSubscription){
			this.deleteAnswerSubscription.unsubscribe();
		}
		if (this.saveTextSubscription){
			this.saveTextSubscription.unsubscribe();
		}
		if (this.saveAnswerTextSubscription){
			this.saveAnswerTextSubscription.unsubscribe();
		}
		if (this.saveAnswerCorrectSubscription){
			this.saveAnswerCorrectSubscription.unsubscribe();
		}
	}

	addAnswer() {
		if (confirm('Biztos hogy új választ akar létrehozni?')) {
			this.addingAnswer = true;
			this.addAnswerSubscription = this.answerService.addAnswer(this.question.id)
				.subscribe((answer:Answer) => {
					this.addingAnswer = false;
					this.question.answers.push(answer);
				})
		}
	}
	deleteAnswer(answerId:number) {
    	if (confirm('Biztosan törlni akarod a választ?')) {
			let answerIndex = this.answerService.getAnswerIndexById(answerId, this.question.answers);
			this.question.answers.splice(answerIndex, 1);
			this.deleteAnswerSubscription = this.answerService.deleteAnswer(answerId).subscribe();
		}
	}

	getCorrectText(correct:boolean) {
		let text:string;

		this.correctOptions.forEach((correctOption) => {
			if (correctOption.value === correct) {
				text = correctOption.title;
			}
		})

		return text;
	}

	onSendFile (event) {
		this.imageService.saveImage(this.question.id, event).subscribe((image) => {
			this.question.images.push(image)
		});
	}

	saveText(text) {
		this.question.text = text;
		this.saveTextSubscription = this.questionService.saveText(this.question.id, text).subscribe();
	}

	saveAnswerText(text:string, index:number){
		this.question.answers[index].text=text;
		this.saveAnswerTextSubscription = this.answerService.saveText(this.question.answers[index].id, text).subscribe();
	}

	saveAnswerCorrect(correct:boolean, index:number){
		this.question.answers[index].correct=correct;
		this.saveAnswerCorrectSubscription = this.answerService.saveCorrect(this.question.answers[index].id, correct).subscribe();
	}
}