import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';


import { Subscription } from 'rxjs';


import { Question } from '../../../../../../models/question';
import { QuestionService } from '../../../../../../services/question.service';

@Component({
  selector: 'questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionsComponent implements OnInit, OnDestroy {

  addingQuestion:boolean;
  
  @Input('locked') locked:boolean;
  @Input('questions') questions:Question[];
  @Input('testId') testId:number;

  private addQuestionSubscription:Subscription;
  private deleteQuestionSubscription:Subscription;
  
  constructor (private questionService:QuestionService) { }

  ngOnInit () {
  }

  ngOnDestroy () {
    if (this.addQuestionSubscription) {
      this.addQuestionSubscription.unsubscribe();
    }
    if (this.deleteQuestionSubscription) {
      this.deleteQuestionSubscription.unsubscribe();
    }
  }

  addQuestion() {
    this.addingQuestion = true;
		this.addQuestionSubscription = this.questionService.addQuestion(this.testId).subscribe(question => {
      this.addingQuestion = false;
			this.questions.push(question);
		})
	}

	deleteQuestion(questionId) {
		if(confirm('Biztos törölni akarod a kérdést?')) {
			let questionIndex = this.questionService.getQuestionIndexById(questionId, this.questions)
			this.questions.splice(questionIndex, 1);
			this.deleteQuestionSubscription = this.questionService.deleteQuestion(questionId).subscribe();
		}
	}

}
