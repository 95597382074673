import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Test} from '../../../../../../models/test'
import { AnswerService } from '../../../../../../services/answer.service';
import { ImageService } from 'src/app/services/image.service';
import { QuestionService } from '../../../../../../services/question.service';
import { TestService } from '../../../../../../services/test.service';

@Component({
	selector: 'edit',
	templateUrl:'./edit.component.html',
	styleUrls: ['./edit.component.scss'],
	providers:[QuestionService,ImageService,AnswerService],
	encapsulation: ViewEncapsulation.None
})
export class EditComponent implements OnInit, OnDestroy{
	test:Test;
	private getStartDataSubscription:Subscription;
	constructor(private testService:TestService, private router:Router, private route:ActivatedRoute,
				private questionService:QuestionService){}

	ngOnInit(){
		this.test = {
			id: +this.route.snapshot.paramMap.get('id')
		}
		

		this.testService.setId(this.test.id);
		this.getStartDataSubscription = this.testService.getStartData()
		.pipe(
			switchMap((test:Test) => {
				this.test = test;
				console.log(this.test);
				return this.questionService.getQuestionsObject(this.test.id, true);
			})
		).subscribe(questions => {
			this.test.questions = questions['questions'];
			this.test.pageQuestionNumber = questions['pageQuestionNumber']
		})

	}

	ngOnDestroy () {
		this.getStartDataSubscription.unsubscribe();
	}

	onStopEditing(edited){
		switch(edited.type){
			case 'testName':
				this.saveTestName(edited.value);break;
			case 'testPageQuestionNumber':
				this.savePageQuestionNumber(edited.value);break;
			case 'groupTypeId':
				this.saveGroupTypeId(edited.value);break;
		}
	}

	saveGroupTypeId (groupTypeId:number) {
		this.testService.setGroupTypeId(groupTypeId)
		this.testService.saveGroupTypeId().subscribe();
	}

	saveTestName(name:string){
		this.testService.setName(name);
		this.testService.saveName().subscribe();
	}

	saveTestDescription(data:any){
		const description = data.editor['_'].data;
		this.testService.setDescription(description);
		this.testService.saveDescription().subscribe();
	}

	savePageQuestionNumber(pageQuestionNumber:number){
		this.testService.setPageQuestionNumber(pageQuestionNumber);
		this.testService.savePageQuestionNumber().subscribe();
	}
	
}