import { Injectable } from '@angular/core';


import { map, tap } from 'rxjs/operators';


import { Question } from '../models/question';
import { Test } from '../models/test';

import { NetworkService } from './network.service';
import { Observable } from 'rxjs';

@Injectable()
export class TestService{
	public test:Test = {};
	public tests:Test[];
	constructor(private networkService:NetworkService){}

	addTest () {
		return this.networkService.post('test/addTest', {});
	}

	copyTest (id) {
		return this.networkService.post('test/copyTest', {id: id});
	}

	deleteTest (id) {
		return this.networkService.delete('test/' + id);
	}

	filterTestsByGroupTypeId (tests:Test[], groupTypeId:number) {
		if (!groupTypeId) {
			return tests;
		}
		return tests.filter(test => {
			return test.groupType.id == groupTypeId;
		})
	}

	getId():number {
		return this.test.id;
	}

	getIndexById(id:number, tests:Test[]):number {
		return tests.findIndex((test) => {
			if (test.id == id) {
				return true;
			}
		})
	}

	getStartData(){
		return this.networkService.get(`test/${this.test.id}/getStartData`)
		.pipe(
			map((test:any) => {
				this.test.name = test.name;
				this.test.description = test.description;
				this.test.groupType = test.group_type;
				this.test.locked = test.locked;
			return this.test;
			})
		)
	}

	getTest () {
		return this.test;
	}

	getTests(trash:boolean, groupTypeId:number):Observable<Test[]>{
		this.tests=[];
		return <Observable<Test[]>>this.networkService.get(`test/getTests/${trash}/${groupTypeId}`)
		.pipe(
			map((tests:any[]) => {
				tests = tests.map((test:any) => {
					test.groupType = test.group_type
					test.totalScore = test.total_score
					return test;
				})
				return tests;
			}),
			tap((tests:Test[]) => {
				this.tests = tests
			})
		)
	}
	//put
    saveDescription(){
        return this.networkService.put('test/saveDescription',{id:this.test.id,description:this.test.description});
	}
	
	saveLocked (id:number, locked:boolean) {
		return this.networkService.put('test/saveLocked', { id, locked })
	}

	// put
	saveName(){
		return this.networkService.put('test/saveName',{id:this.test.id,name:this.test.name});
	}
	// put
	saveGroupTypeId () {
		return this.networkService.put('test/saveGroupTypeId', {id: this.test.id, groupTypeId: this.test.groupTypeId});
	}
	//put
	savePageQuestionNumber(){
		return this.networkService.put('test/savePageQuestionNumber',{id:this.test.id,pageQuestionNumber:this.test.pageQuestionNumber});
	}

	setId(id:number){
		this.test.id=id;
	}
	setName(name:string){
		this.test.name=name;
	}

	setDescription(description:string){
		this.test.description=description;
	}

	setGroupTypeId (groupTypeId:number) {
		this.test.groupTypeId = groupTypeId;
	}

	setPageQuestionNumber(pageQuestionNumber){
		this.test.pageQuestionNumber=pageQuestionNumber;
	}

	setQuestions(questions:Question[]){
		this.test.questions=questions;
	}
	// patch
	trashTest (id:number){
		return this.networkService.put('test/trashTest', {id: id});
	}
	// patch
	untrashTest (id:number){
		return this.networkService.put('test/untrashTest', {id: id});
	}
	// patch
	valuate(fillId:number){
		return this.networkService.put('test/valuate', {fillId: fillId, test: this.test})
		.pipe(
			map((result:any) => {
					this.test.score=parseFloat(result.score);
					this.test.totalScore=parseFloat(result.totalScore);
					this.test.time = result.time;
					for(let question of this.test.questions){
						question.answers=question.answers.map(answer => {
							for(let resultAnswer of result.answers){
								if(answer.id === resultAnswer.id){
									answer.correct = resultAnswer.correct;
									return answer;
								}
							}
						})
					}
			}))
	}
}