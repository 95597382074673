import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CookieBarComponent implements OnInit {

  alreadyAcknowledged:boolean = false;
  
  constructor(private cookieService:CookieService) { }

  ngOnInit() {
    this.alreadyAcknowledged = this.cookieService.getAcknowledged();
  }

  acknowledged () {
    this.alreadyAcknowledged = true;
    this.cookieService.setAcknowledged(this.alreadyAcknowledged);
  }

}
