import { Injectable } from '@angular/core';
import { Router, CanActivate,  ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators'

import { User } from '../models/user';
import { UserService } from './user.service';


@Injectable()
export class UserGuard implements CanActivate {
	constructor(private userService:UserService, private router:Router){}

	canActivate (route:ActivatedRouteSnapshot):Observable<boolean>{
        return this.userService.getUserSubject().pipe(
            switchMap((user:User) => {
                let roleId = route.data.roleId;
                if(user.loggedIn && user.role.id == roleId) {
                    return of(true);
                } else if (user.loggedIn && roleId.substring(0,1) === '!' && user.role.id != roleId.substring(1)) {
                    return of(true);
                } else {
                    this.handleUnauthorized(roleId);
                }
            })
        )
    }

    private handleUnauthorized (roleId:string):Observable<boolean> {
        let route = this.getRoute(roleId);
        this.router.navigate([route]);
        return of(false);
    }

    private getRoute (roleId):string {
        let route;
        switch (roleId) {
            case '2':
             route = '/user/log-in/2'; break;
            default: 
                route = '/admin/user/log-in/!2'; break;
            
        }
        return route;
    }
}