import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { Subscription } from 'rxjs';

import { Note } from 'src/app/models/note';
import { NoteService } from 'src/app/services/note.service';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotesComponent implements OnInit {

  loading:boolean;
  notes:Note[];
  notesSubscription:Subscription;
  @Input('groupTypeId') groupTypeId:number;

  constructor(private noteService:NoteService) { }

    ngOnInit() {
      this.loading = true;
      this.notesSubscription = this.noteService.getNotes(this.groupTypeId).subscribe(notes => {
        this.loading = false;
        this.notes = notes;
      });
    }

  ngOnDestroy () {
    this.notesSubscription.unsubscribe();
  }

}
