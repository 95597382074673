import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { NotesComponent } from './components/notes/notes.component';
import { FormsModule } from '@angular/forms';
import { EditNoteComponent } from './edit-note/edit-note.component';
import { GroupTypeModule } from '../group-type/group-type.module';
import { NoteComponent } from './components/note/note.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PdfViewerModule,
    GroupTypeModule,
    SharedModule
  ],
  declarations: [NotesComponent, EditNoteComponent, NoteComponent]
})
export class NoteModule { }
