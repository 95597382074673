import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { TimeService } from 'src/app/services/time.service';
import { UserService } from 'src/app/services/user.service';
import { JwtService } from 'src/app/services/jwt.service';

@Component({
  selector: 'expire-alert',
  templateUrl: './expire-alert.component.html',
  styleUrls: ['./expire-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpireAlertComponent implements OnInit {

  @Output('alertdismissed') alertDismissed:EventEmitter<boolean> = new EventEmitter();
  
  constructor(private jwtService:JwtService, private timeService:TimeService, private userService:UserService) { }

  ngOnInit() {
  }

  dismissAlert () {
    this.alertDismissed.emit(true);
  }

  restart () {
    this.timeService.restart();
    this.userService.refreshToken().subscribe((response:any) => {
      this.jwtService.setToken(response.token)
    })
    this.dismissAlert();
  }

}
