import { Injectable } from '@angular/core';

import { JwtPayload } from '../models/jwt-payload'

@Injectable()
export class JwtService{
    private _token:string;

    decode(){
        var parts=this._token.split(/\./);
        var payloadJson=JSON.parse(atob(parts[1]));

        var payload:JwtPayload = {
            id: payloadJson.sub,
            roleId: +payloadJson.roleId
        };

        return payload;
    }

    get token () {
        return this._token
    }

    setToken(token){
        this._token=token;
    }

}
