import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Test } from '../../../../../../models/test';


import { Subscription } from 'rxjs';


import { TestService } from '../../../../../../services/test.service';

@Component({
  selector: 'tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestsComponent implements OnInit, OnDestroy {

  loading:boolean;
  tests:Test[];
  testsSubscription:Subscription;
  @Input('groupTypeId') groupTypeId:number;
  constructor(private testService:TestService) { }

  ngOnInit() {
      this.loading = true;
      this.testsSubscription = this.testService.getTests(false, this.groupTypeId).subscribe(tests => {
        this.loading = false;
        this.tests = tests;
      });
  }

  ngOnDestroy () {
    this.testsSubscription.unsubscribe();
  }

}
