import { Injectable } from '@angular/core';


import { tap, map } from 'rxjs/operators';


import { Fill } from  '../models/fill';
import { NetworkService } from './network.service';
import { FillFilter } from '../models/fill-filter';
import { Observable } from 'rxjs';

@Injectable()
export class FillService{
	public fill:Fill = {};
	constructor(private networkService:NetworkService){}

	exportCsv (ids:number[]) {
		return this.networkService.postText('fill/exportCsv', { ids });
	}

	getFills (testId?:number):Observable<Fill[]> {
		
		const fills = (testId) ? this.networkService.get(`fill/${testId}/getFills`) : this.networkService.get('fills');
		return <Observable<Fill[]>> fills.pipe(map((fills:Fill[]) => {
			fills.map((fill:any) => {
				fill.date = new Date(fill.date);
				fill.test.groupType = fill.test.group_type
				return fill;
			})
			return fills;
		}))
	}

	getFill(id:number){
		return this.networkService.get('fill/' + id);
	}

	getId ():number {
		return this.fill.id;
	}

	setId (id) {
		this.fill.id = id;
	}

	createFill (testId:number, userId:number) {
		return this.networkService.post('fill', {testId, userId})
			.pipe(
				tap((fill:Fill) => {
					this.fill.id = fill.id
				})
			)
	}

	deleteFill (id) {
		return this.networkService.delete('fill/' + id)
	}

	filterFills (fills:Fill[], filter:FillFilter) {
		if (filter.from) {
			fills = fills.filter((fill) => {
				if (filter.from.getTime() < fill.date.getTime()){
					return true;
				}
			})
		}
		if (filter.to) {
			const oneDay = 1000 * 60 * 60 * 24
			fills = fills.filter((fill) => {
				if (fill.date.getTime() < filter.to.getTime() + oneDay){
					return true;
				}
			})
		}
		if (filter.group) {
			fills = fills.filter((fill) => {
				if (fill.user.group.id == filter.group){
					return true;
				}
			})
		}
		if (filter.user) {
			fills = fills.filter((fill) => {
				if (fill.user.id == filter.user){
					return true;
				}
			})
		}
		if (filter.groupType) {
			fills = fills.filter((fill) => {
				if (fill.test.groupType.id == filter.groupType){
					return true;
				}
			})
		}
		if (filter.test) {
			fills = fills.filter((fill) => {
				if (fill.test.id == filter.test){
					return true;
				}
			})
		}
		return fills;
	}
}