import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { of } from "rxjs";
import { switchMap } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { JwtService } from "./jwt.service";

@Injectable()
export class NetworkService {
  private url: string = environment.production
    ? "https://kamionsuli-tanuloszoba.hu/api/public/"
    : "https://kamionsuli-teszt.farmasibulcsu.hu/api/public/";
  

  constructor(private httpClient: HttpClient, private jwtService: JwtService) {}

  delete(urlPart: string) {
    urlPart = this.addAuthorization(urlPart);
    return this.httpClient.delete(this.url + urlPart).pipe(
      switchMap((response: any) => {
        if (response && response.token) {
          this.jwtService.setToken(response.token);
          urlPart = this.addAuthorization(urlPart);
          return this.httpClient.delete(this.url + urlPart);
        } else {
          return of(response);
        }
      })
    );
  }

  get(urlPart: string) {
    urlPart = this.addAuthorization(urlPart);
    return this.httpClient.get(this.url + urlPart).pipe(
      switchMap((response: any) => {
        if (response && response.token) {
          this.jwtService.setToken(response.token);
          urlPart = this.addAuthorization(urlPart);
          return this.httpClient.get(this.url + urlPart);
        } else {
          return of(response);
        }
      })
    );
  }

  patch(urlPart: string, body: any) {
    urlPart = this.addAuthorization(urlPart);
    return this.httpClient.patch(this.url + urlPart, body).pipe(
      switchMap((response: any) => {
        if (response && response.token) {
          this.jwtService.setToken(response.token);
          urlPart = this.addAuthorization(urlPart);
          return this.httpClient.patch(this.url + urlPart, body);
        } else {
          return of(response);
        }
      })
    );
  }

  put(urlPart: string, body: any) {
    urlPart = this.addAuthorization(urlPart);
    return this.httpClient.put(this.url + urlPart, body).pipe(
      switchMap((response: any) => {
        if (response && response.token) {
          this.jwtService.setToken(response.token);
          urlPart = this.addAuthorization(urlPart);
          return this.httpClient.put(this.url + urlPart, body);
        } else {
          return of(response);
        }
      })
    );
  }

  post(urlPart: string, body: any) {
    urlPart = this.addAuthorization(urlPart);
    return this.httpClient.post(this.url + urlPart, body).pipe(
      switchMap((response: any) => {
        if (response && response.token) {
          this.jwtService.setToken(response.token);
          urlPart = this.addAuthorization(urlPart);
          return this.httpClient.post(this.url + urlPart, body);
        } else {
          return of(response);
        }
      })
    );
  }

  postText(urlPart: string, body: any) {
    urlPart = this.addAuthorization(urlPart);
    return this.httpClient
      .post(this.url + urlPart, body, { responseType: "text" as "json" })
      .pipe(
        switchMap((response: any) => {
          if (response && response.token) {
            this.jwtService.setToken(response.token);
            urlPart = this.addAuthorization(urlPart);
            return this.httpClient.post(this.url + urlPart, body, {
              responseType: "text" as "json"
            });
          } else {
            return of(response);
          }
        })
      );
  }

  private addAuthorization(urlPart: string): string {
    if (this.jwtService.token) {
      urlPart += "?token=" + this.jwtService.token;
    }
    return urlPart;
  }
}
