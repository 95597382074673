import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { GroupType } from 'src/app/models/group-type';
import { Edited } from 'src/app/models/edited';

@Component({
  selector: 'editable-group-type-select',
  templateUrl: './editable-group-type-select.component.html',
  styleUrls: ['./editable-group-type-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditableGroupTypeSelectComponent implements OnInit {

  editing:boolean;
  groupType:GroupType;
  @Input('hiddenTypes')
  @Input('selectedId') selectedId:number;
  @Output('stopEditing') stopEditing:EventEmitter<Edited> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  onGroupTypeChange (groupType:GroupType) {
    this.groupType = groupType;
    this.selectedId = groupType.id;
  }

  onStartEditing () {
    this.editing = true;
  }

  onStopEditing () {
    this.editing = false;
    const edited:Edited = {
      type: 'groupTypeId',
      value: this.selectedId
    }
    this.stopEditing.emit(edited);
  }

}
