import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Test } from '../../../../../../models/test';
import { User } from 'src/app/models/user';
import { TestService } from '../../../../../../services/test.service';
import { TimeService } from 'src/app/services/time.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	templateUrl:'./tests.component.html',
	styleUrls: ['./tests.component.scss']
})

export class TestsComponent implements OnInit, OnDestroy{
	
	addingTest:boolean;
	loading:boolean
	tests:Test[];
	toggleSuccess:boolean;
	toggleSended;
	toggling:boolean;
	user:User; 
	private testsSubscription:Subscription;
	private addSubscription:Subscription;
	private copySubscription:Subscription;
	private lockedSubscription:Subscription;
	private trashSubscription:Subscription;
	private userSubscription:Subscription;


	constructor(private testService:TestService, private timeService:TimeService, private userService:UserService, private router:Router){}

	ngOnInit(){
		this.loading = true;
		this.testsSubscription = this.testService.getTests(false, 0).subscribe(tests => {
			this.tests = tests;
			this.loading = false;
		});
		this.userSubscription = this.userService.getUserSubject().subscribe(user => {
			this.user = user;
		})
	}

	ngOnDestroy () {
		this.testsSubscription.unsubscribe();
		this.userSubscription.unsubscribe();
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}
		if (this.copySubscription) {
			this.copySubscription.unsubscribe();
		}
		if (this.lockedSubscription) {
			this.lockedSubscription.unsubscribe();
		}
		if (this.trashSubscription) {
			this.trashSubscription.unsubscribe();
		}
	}

	addTest () {
		this.addingTest = true
		this.addSubscription = this.testService.addTest().subscribe((test:Test)=> {
			this.addingTest = false;
			this.timeService.restart();
			this.router.navigate(['/admin/edit', test.id]);
		});
	}

	copyTest (id:number) {
		this.copySubscription = this.testService.copyTest(id).subscribe((test:Test) => {
			this.tests.push(test);
		})
	}

	toggleLockTest (test:Test, locked:boolean) {
		if(confirm('Biztosan fel akarod oldani / le akarod zárni ezt a tesztet?')) {
			this.toggling = true;
			this.lockedSubscription = this.testService.saveLocked(test.id, locked).subscribe((response:any) => {
				if (response.success) {
					test.locked = locked;
				}
				this.toggleSuccess = response.success;
				this.toggleSended = true; 
				this.toggling = false;
			});
			setTimeout(() => {
				this.toggleSended = false;
			}, 20000)
		}
	}

	trashTest (id:number) {
		if (confirm('Biztosan kukába akarod helyezni a tesztet?')) {
			this.trashSubscription = this.testService.trashTest(id).subscribe(() => {
				let testIndex = this.testService.getIndexById(id, this.tests);
				this.tests.splice(testIndex, 1);
			})
		}
	}
}