import { Injectable } from '@angular/core';

import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';


import { User } from '../models/user'

import { NetworkService } from './network.service';
import { JwtService } from './jwt.service';
import { identifierModuleUrl } from '@angular/compiler';
import { useAnimation } from '@angular/animations';

@Injectable({
	providedIn: 'root'
})
export class UserService{
	private user:User;
	private userSubject:BehaviorSubject<User>;
	constructor(private networkService:NetworkService, private jwtService:JwtService){
		this.user = {
			loggedIn:false
		}
		this.userSubject=new BehaviorSubject(this.user);
	}

	addUser (groupId:number) {
		return this.networkService.post(`user/${groupId}/addUser`,{});
	}

	authenticate(creditentals){
		return this.networkService.post('user/authenticate',creditentals)
		.pipe(
			tap((response:any) => {
				if(response.token){
					this.jwtService.setToken(response.token);
					this.logIn();
				}
			})
		)
	
	}

	getUser (id:number) {
		return this.networkService.get(`user/${id}/getUser`).pipe(
			map((user:User) => {
				user.group.accessFrom = new Date(user.group.accessFrom);
				user.group.accessTo = new Date(user.group.accessTo);
				return user;
			})
		)
	} 

	getUserSubject () {
		return this.userSubject;
	}

	logIn(){
		let payload = this.jwtService.decode();
		this.networkService.get(`user/${payload.id}/getUser`).subscribe((user:User) => {
			this.user = {
				id: user.id,
				name: user.name,
				email: user.email,
				role: {
					id: user.role.id,
					name: user.role.name
				},
				loggedIn: true
			}

			if (user.group) {
				this.user.group = {
						id: user.group.id,
						name: user.group.name,
						accessFrom: user.group.accessFrom,
						accessTo: user.group.accessTo,
						type: {
							id: user.group.type.id,
							name: user.group.type.name
					}
				}
			}
			this.userSubject.next(this.user);
		})
	}

	logOut(){
		this.user.loggedIn = false;
		this.userSubject.next(this.user);
	}

	getUsers (groupId:number):Observable<User[]> {
		return <Observable<User[]>>this.networkService.get(`user/${groupId}/getUsers`)
	}

	deleteUser (id:number) {
		return this.networkService.delete('user/' + id)
			.pipe(
				map((response:any) => {
					if (response.errorCode) {
						throwError(response);
					}
				}
			)
		)
	}

	filterUsersByGroupId (users:User[], groupId:number) {
		if (!groupId) {
			return users;
		}
		return users.filter(user => {
			return user.group.id == groupId;
		})
	}

	resetPassword(id:number, password:string) {
		return this.networkService.post(`user/${id}/resetPassword`, { password })
	}


	refreshToken () {
		return this.networkService.post('user/refreshToken', { user: { id: this.user.id, sub: this.user.id, roleId: this.user.role.id }});
	}

	// patch
	saveUser(user:User) {
		return this.networkService.put(`user/${user.id}/saveUser`, { name: user.name, email: user.email  })
		.pipe(
			map((response:any) => {
				if (response && response.errorCode) {
					throwError(response);
				}
			}
			)
		)
	}
	// patch
	sendNotificationEmail (id:number) {
		return this.networkService.put(`user/${id}/sendNotificationEmail`,{})
		.pipe(
			map((response:any) => {
				if (response && response.errorCode) {
					throwError(response);
				}
			}
			)
		)
	}

	sendResetPassword (email:string) {
		return this.networkService.post('user/sendResetPassword', { email });
	}

	getByPasswordRetrieverCode (passwordRetrieverCode:string) {
		return this.networkService.get('user/getByPasswordRetrieverCode/' + passwordRetrieverCode);
	}
}