import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ViewEncapsulation
} from "@angular/core";

import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";

import { Test } from "../../../../../../models/test";

import { TestService } from "../../../../../../services/test.service";
import { FillService } from "../../../../../../services/fill.service";
import { UserService } from "../../../../../../services/user.service";

@Component({
  selector: "start",
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class StartComponent implements OnInit, OnDestroy {
  loading: boolean;
  test: Test;
  testStarting: boolean;
  @Output() onStartTest: EventEmitter<any> = new EventEmitter();
  private getStartDataSubscription: Subscription;

  constructor(
    private testService: TestService,
    private fillService: FillService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.getStartDataSubscription = this.testService
      .getStartData()
      .subscribe((test: Test) => {
        this.test = test;
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.getStartDataSubscription.unsubscribe();
  }

  startTest() {
    this.testStarting = true;
    const test = this.testService.getTest();
    this.userService
      .getUserSubject()
      .pipe(
        switchMap(user => {
          if (user.id) {
            return this.fillService.createFill(test.id, user.id);
          }
        })
      )
      .subscribe(() => {
        this.onStartTest.emit();
        this.testStarting = false;
      });
  }
}
