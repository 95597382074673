import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { Subscription } from 'rxjs';

import { PDFProgressData, PDFDocumentProxy } from 'pdfjs-dist';

import { NoteService } from 'src/app/services/note.service';
import { Note } from 'src/app/models/note';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoteComponent implements OnInit, OnDestroy {

  loading:boolean;
  note:Note;
  pdfLoading:boolean;
  pdfLoadingPercent:number;
  pdfLoaded:number = 0;
  pdfTotal:number = 100;
  private noteSubscription:Subscription;
  
  constructor(private noteService:NoteService, private route:ActivatedRoute) { }

  ngOnInit() {
    this.loading = true;  
    const id = +this.route.snapshot.paramMap.get('id');
      this.noteSubscription = this.noteService.getNote(id).subscribe(note => {
        console.log(note.file);
        
        this.note = note;
        this.loading = false;
        this.pdfLoading = true;
      })
  }

  ngOnDestroy () {
    this.noteSubscription.unsubscribe();
  }
  onLoadComplete (pdf: PDFDocumentProxy) {
    this.pdfLoading = false;
  }

  onProgress (progress: PDFProgressData) {

      this.pdfLoadingPercent = progress.loaded / progress.total

      if (this.pdfLoadingPercent > 1) {
        this.pdfLoadingPercent = 1;
      }

      this.pdfLoaded = Math.round(this.pdfLoadingPercent * 100);
      console.log(this.pdfLoaded);
      
      this.pdfTotal = 100;
  }
}