import { Component, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';


import { TimerComponent } from 'src/app/modules/shared/components/timer/timer.component';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { TimeService } from 'src/app/services/time.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements AfterViewInit  {
  alertDisplayed:boolean;
  countDownStarted:boolean;
  index:number = 1;
  startTime:string = '20:00';
  time:string = this.startTime;
  timerVisible:boolean = false;
  restart:Subject<string> = new Subject();
  user:User;
  maintenance:boolean = false;
  @ViewChild(TimerComponent) timerComponent:TimerComponent;

  constructor (private userService:UserService, private timeService:TimeService, private router:Router) {}

  ngAfterViewInit () {
    this.userService.getUserSubject()
    .pipe(
      switchMap((user) => {
          this.user = user;
          if (this.user.loggedIn) {
            this.time = this.startTime
            this.timerComponent.startCountDown();
            this.countDownStarted = true;
            return this.timeService.getRestart()
          } else {
            this.onAlertDismissed();
            this.restart.next(this.startTime);
            this.timerComponent.stopCountDown();
            return of(false);
          }
        }
      )
    )
    .subscribe((restart:boolean) => {
        if (restart) {
          this.restart.next(this.startTime);
        }
    })
  }

  displayAlert () {
    this.alertDisplayed = true;
  }

  logOut () {
    this.userService.logOut();
    this.router.navigate(['/user/log-in/2']);
    this.onAlertDismissed();
    this.countDownStarted = false;
  }

  onAlertDismissed () {
    this.alertDisplayed = false;
  }


  
}
