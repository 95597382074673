import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from '../../../../models/user';
import { UserService } from '../../../../services/user.service';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/group.service';
import { Group } from 'src/app/models/group';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit, OnDestroy {

  addingUser:boolean;
  group:Group;
  groupId:number;
  loading:boolean;
  messages:string[] = [];
  users:User[]
  private addUserSubscription:Subscription;
  private groupSubscription:Subscription;
  private userSubscription:Subscription;

  
  constructor(private userService:UserService, private groupService:GroupService, private router:Router, private route:ActivatedRoute) { }

  ngOnInit () {
    this.loading = true;
    this.groupId = +this.route.snapshot.paramMap.get('groupId');
    if  (this.groupId !== 0) {
      this.groupSubscription = this.groupService.getGroup(this.groupId).subscribe(group => {
        this.group = group;
      })
    }
    this.userSubscription = this.userService.getUsers(this.groupId).subscribe(users => {
      this.loading = false;
      this.users = users;
    });
  }

  ngOnDestroy () {
    if (this.addUserSubscription) {
      this.addUserSubscription.unsubscribe();
    }
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }
    this.userSubscription.unsubscribe();
  }

  addUser () {
    this.addingUser = true;
    this.addUserSubscription = this.userService.addUser(this.groupId).subscribe((user:User) => {
      this.router.navigate(['/admin/user/edit/', this.groupId, user.id]);
      this.addingUser = false;
    })
  }

  checkAll () {
    this.users = this.users.map(user => {
      user.checked = true;
      return user;
    })
  }

  deleteChecked () {
    
    if (confirm('Biztosan törölni akarod a felhasználókat?')){
      const deleteableUsers:User[] = [];
      this.users = this.users.filter((user, index) => {
        if(user.checked) {
          user.index = index;
          deleteableUsers.push(user);
          return false;
        } else {
          return true;
        }
      });


      for (let deleteableUser of deleteableUsers) {
        setTimeout(() => {
          this.userService.deleteUser(deleteableUser.id).subscribe(
            () => {}, 
            () => {
                  this.messages.push(`A ${deleteableUser.name} nevű felhasználó törlése sikertelen`);
                  this.users.splice(deleteableUser.index,  0, deleteableUser)
            }
          )
        }, 500 )
        
      }

    
      setTimeout(() => {
        this.messages = [];
      },10000)
    }
  }

  orderAsc (type:string) {
		this.users.sort(function(a,b){
			if(a[type] < b[type]){
				return -1;
			}else if(a[type] > b[type]){
				return 1;
			}
			return 0;
		})
	}

	orderDesc (type:string) {
		this.users.sort(function(a,b){
			if(a[type] > b[type]){
				return -1;
			}else if(a[type] < b[type]){
				return 1;
			}
			return 0;
		})
	}

}
