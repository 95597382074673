import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VersionComponent implements OnInit {

  version:string;
  
  constructor() { }

  ngOnInit() {
    this.version = '1.0';
  }

}
