import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { GoToTopComponent } from './components/go-to-top/go-to-top.component';
import { NavigateDirective } from './directives/navigate.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { ExpireAlertComponent } from './components/expire-alert/expire-alert.component';
import { TimerComponent } from './components/timer/timer.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [ GoToTopComponent, NavigateDirective, LoaderComponent, ExpireAlertComponent, TimerComponent, MaintenanceComponent ],
  exports: [ GoToTopComponent, NavigateDirective, LoaderComponent, ExpireAlertComponent, TimerComponent, MaintenanceComponent ]
})
export class SharedModule { }
