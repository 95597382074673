import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';

import { QuestionsComponent } from '../questions/questions.component';
import { StartComponent } from '../start/start.component';

import { TestService } from '../../../../../../services/test.service';
import { TimeService } from 'src/app/services/time.service';


@Component({
	selector: 'test',
	templateUrl:'./test.component.html',
	styleUrls:['./test.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class TestComponent implements OnInit{
	public currentState:string;
	public passedSeconds:number;
	@ViewChild(QuestionsComponent)
	private questionsComponent:QuestionsComponent;
	@ViewChild(StartComponent)
	private startComponent:StartComponent;
	constructor(private route:ActivatedRoute, private viewportScroller:ViewportScroller, private testService:TestService, private timeService:TimeService){}

	ngOnInit():void{
		this.currentState='start';
		this.testService.setId(+this.route.snapshot.paramMap.get('id'));
	}

	onStartTest(){
		this.currentState="questions";
		this.questionsComponent.nextPage();
		this.timeService.restart();
	}

	onBackToStart(){
		this.currentState="start";
		this.timeService.restart();
	}

	onGoToValuation(){
		this.currentState="valuation";
		this.timeService.restart();
	}

	onGoToWrongQuestions (state:string) {
		this.timeService.restart();
		this.viewportScroller.scrollToPosition([0,0]);
		this.currentState = state;
	}

	onBackToTest(){
		this.currentState="questions";
		this.timeService.restart();
	}

}