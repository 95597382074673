import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

import { GroupType } from 'src/app/models/group-type';
import { GroupTypeService } from 'src/app/services/group-type.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'group-type-select',
  templateUrl: './group-type-select.component.html',
  styleUrls: ['./group-type-select.component.scss']
})
export class GroupTypeSelectComponent implements OnInit, OnDestroy {

  groupTypes:GroupType[];
  groupTypesSubscription:Subscription;
  selectedGroupType:GroupType;
  @Input('selectedId') selectedId:number;
  @Input('hiddenGroupTypes') hiddenGroupTypes:number[] = [];
  @Output('groupTypeChange') change:EventEmitter<GroupType> = new EventEmitter();

  constructor(private groupTypeService:GroupTypeService) { }

  ngOnInit() {
    this.groupTypesSubscription = this.groupTypeService.getGroupTypes().subscribe(groupTypes => {
      this.groupTypes = groupTypes.filter(groupType => {
        return this.hiddenGroupTypes.indexOf(groupType.id) === -1;
      })
      this.findSelectedGroupType();
      this.change.emit(this.selectedGroupType);
    })
  }

  ngOnDestroy () {
    this.groupTypesSubscription.unsubscribe();
  }

  onChange (event) {
    this.findSelectedGroupType();
    this.change.emit(this.selectedGroupType);
  }

  private findSelectedGroupType () {
    this.selectedGroupType = this.groupTypes.filter(groupType => {
        return groupType.id == this.selectedId;
    })[0];
  }

}
