import { Component } from '@angular/core';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'request-test',
  templateUrl: './request-test.component.html',
  styleUrls: ['./request-test.component.scss']
})
export class RequestTestComponent  {

  requestStatuses:RequestStatuses;

  textSuccessful:string = 'Request is successful';
  textFailed:string = 'Request is failed';


  constructor(private networkService:NetworkService) { }

  ngOnInit() {
    this.requestStatuses = {
      delete: {
        sent: false
      },
      get: {
        sent: false
      },
      patch: {
        sent: false
      },
      put: {
        sent: false
      },
      post: {
        sent: false
      }
    }
  }

  sendDeleteRequest() {
    this.requestStatuses.delete.sent = false;
    this.networkService.delete('requestTest').subscribe((response:any) => {
      this.requestStatuses.delete.sent = true;
      this.requestStatuses.delete.successful = true;
      console.log(this.requestStatuses);
      
    },
    (error:any) => {
      this.requestStatuses.delete.sent = true;
      this.requestStatuses.delete.successful = false;
      console.log(this.requestStatuses);
    })
  }

  sendGetRequest() {
    this.requestStatuses.get.sent = false;
    this.networkService.get('requestTest').subscribe((response:any) => {
      this.requestStatuses.get.sent = true;
      this.requestStatuses.get.successful = true;
      console.log(this.requestStatuses);
    },
    (error:any) => {
      this.requestStatuses.get.sent = true;
      this.requestStatuses.get.successful = false;
      console.log(this.requestStatuses);
    })
  }

  sendPatchRequest() {
    this.requestStatuses.patch.sent = false;
    this.networkService.patch('requestTest', {'key': 'value'}).subscribe((response:any) => {
      this.requestStatuses.patch.sent = true;
      this.requestStatuses.patch.successful = true;
      console.log(this.requestStatuses);
    },
    (error:any) => {
      this.requestStatuses.patch.sent = true;
      this.requestStatuses.patch.successful = false;
      console.log(this.requestStatuses);
    })
  }

  sendPutRequest() {
    this.requestStatuses.put.sent = false;
    this.networkService.put('requestTest', {'key': 'value'}).subscribe((response:any) => {
      this.requestStatuses.put.sent = true;
      this.requestStatuses.put.successful = true;
      console.log(this.requestStatuses);
    },
    (error:any) => {
      this.requestStatuses.put.sent = true;
      this.requestStatuses.put.successful = false;
      console.log(this.requestStatuses);
    })
  }

  sendPostRequest() {
    this.requestStatuses.post.sent = false;
    this.networkService.post('requestTest', {'key': 'value'}).subscribe((response:any) => {
      this.requestStatuses.post.sent = true;
      this.requestStatuses.post.successful = true;
    },
    (error:any) => {
      this.requestStatuses.post.sent = true;
      this.requestStatuses.post.successful = false;
    })
  }

}

interface RequestStatuses {
  delete: RequestStatus;
  get: RequestStatus;
  patch: RequestStatus;
  put: RequestStatus;
  post: RequestStatus;
}

interface RequestStatus {
  sent: boolean;
  successful?: boolean
}
