import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { Subscription } from 'rxjs';


import { User } from '../../../../models/user';

import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {

  emailError:boolean;
  emailSuccess:boolean;
  groupId:number;
  error:boolean;
  loading:boolean
  saving:boolean;
  sending:boolean;
  success:boolean;
  user:User;
  private saveUserSubscription:Subscription;
  private sendNotificationEmailSubscription:Subscription;
  private userSubscription:Subscription;
  constructor(private route:ActivatedRoute, private userService:UserService, private router:Router) { }

  ngOnInit() {
    this.loading = true;
    this.groupId = +this.route.snapshot.paramMap.get('groupId');
    let id:number = +this.route.snapshot.paramMap.get('id');
    this.userSubscription = this.userService.getUser(id).subscribe((user:User) => {
      this.user = user;
      this.loading = false;
    });
  }

  ngOnDestroy () {
    if (this.saveUserSubscription) {
      this.saveUserSubscription.unsubscribe();
    }
    if (this.sendNotificationEmailSubscription) {
      this.sendNotificationEmailSubscription.unsubscribe();
    }
    this.userSubscription.unsubscribe();
  } 

  saveUser (form) {
    this.saving = true; 
    this.saveUserSubscription = this.userService.saveUser(this.user).subscribe(
      () => {
        this.success = true;
      }, 
      () => {
        this.error = true;
      },
      () => {
        this.saving = false;
      }
    );
    setTimeout(() => {
      this.success = false;
      this.error = false;
    }, 10000)
  }

  sendNotificationEmail () {
    this.sending = true;
    this.sendNotificationEmailSubscription = this.userService.sendNotificationEmail(this.user.id).subscribe(
     () => {
        this.emailSuccess = true;
     }, 
     () => {
       this.emailError = true;
     }, 
     () => {
       this.sending = false
     });
     setTimeout(() => {
        this.emailSuccess = false;
        this.emailError = false;
      }, 10000)
  }

}
