import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'editable-select',
  templateUrl: './editable-select.component.html',
  styleUrls: ['./editable-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditableSelectComponent implements OnInit {

  editing:boolean
  selectedOption//:any
  @Input('options') options:any[]
  @Input('selectedOption') selectedOptionValue:any;
  @Output('stopEditing') stopEditing:EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
    this.findSelectedOption();
  }

  onSelectChange () {
    this.selectedOptionValue = (this.selectedOptionValue == "true");
  }

  onStartEditing () {
    this.editing = true;
  }

  onStopEditing () {
    this.editing = false;
    this.findSelectedOption();
    this.stopEditing.emit(this.selectedOptionValue);
  }

  private findSelectedOption () {
    this.selectedOption = this.options.filter(option => {
        return option.value == this.selectedOptionValue;
    })[0];
  }

}
