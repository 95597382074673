import { Component, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription, Subject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TimerComponent } from 'src/app/modules/shared/components/timer/timer.component';
import { User } from 'src/app/models/user';
import { UserService } from '../../../../services/user.service';
import { TimeService } from 'src/app/services/time.service';


@Component({
	selector:'testr-admin',
	templateUrl:'./admin.component.html',
	styleUrls:['./admin.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class AdminComponent implements AfterViewInit {

	alertDisplayed:boolean;
	countDownStarted:boolean;
	restart:Subject<string> = new Subject();
	startTime:string = '20:00';
	time:string = this.startTime;
	timerVisible:boolean = false;
	user:User = {};
	userSubscription:Subscription;
	@ViewChild(TimerComponent) timerComponent:TimerComponent;

	constructor(private timeService:TimeService, private userService:UserService, private router:Router){}

	ngAfterViewInit () {
		this.userService.getUserSubject()
			.pipe(
			  switchMap((user) => {
				  this.user = user;
				  if (this.user.loggedIn) {
					this.time = this.startTime
					this.timerComponent.startCountDown();
					this.countDownStarted = true;
					return this.timeService.getRestart()
				  } else {
					this.onAlertDismissed();
					this.restart.next(this.startTime);
					this.timerComponent.stopCountDown();
					return of(false);
				  }
				}
			  )
			)
			.subscribe((restart:boolean) => {
				if (restart) {
				  this.restart.next(this.startTime);
				}
			})
	}

	displayAlert () {
		this.alertDisplayed = true;
	}

	
	logOut () {
		this.userService.logOut();
		this.router.navigate(['/admin/user/log-in/!2']);
		this.onAlertDismissed();
		this.countDownStarted = false;
	}
	
	  onAlertDismissed () {
		this.alertDisplayed = false;
	  }
};