import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CopyrightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
