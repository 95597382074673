import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


import { components, } from './declarations'

import { NetworkService } from '../../../../services/network.service';


@NgModule({
    imports:[  HttpClientModule, CommonModule, RouterModule ],
    declarations:[...components],
    providers:[ NetworkService  ],
    exports:[...components]
})

export class RequestModule {};